import { ForwardedRef, forwardRef, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { agGridOptions, defaultColDef, gridOptions as defaultGridOptions } from '@/components/table/grid_table_options'
import { AgGridReactWrap, GridToolBarSearch, GridToolBarWrap, GridToolPagination, TABLE_HEADER_EDITABLE } from '@/components/table/grid_styles'
import { cloneDeep, min } from 'lodash-es'
import cx from 'classnames'
import { HStack, VStack } from '@/components/content/Stack'
import Button from '@/components/content/Button'
import GridExportModal from '@/components/table/GridExportModal'
import useModal from '@/hooks/useModal'
import FileInput from '@/components/content/FileInput'
import { GridTableProps } from '@/components/table/grid_types'
import RemixIcon from '@/components/content/RemixIcon'
import { CellValueChangedEvent, FilterChangedEvent, GetRowIdParams, ICellRendererParams } from 'ag-grid-community'
import MToast from '@/utils/MToast'
import MConfirm from '@/utils/MConfirm'
import { Utils } from '@/utils/Utils'

function GridTableInner<T>(props: GridTableProps<T>, customRef: ForwardedRef<AgGridReact>) {
  const {
    className,
    isLoading = false,
    idKey = 'id',
    rowData = [],
    useColumnNo = false,
    columnDefs,
    gridOptions,
    rowSelection = 'multiple',
    rowSelectable,
    pagination = true,
    countPerPage = 0,
    totalCount: _totalCount = 0,
    currentPage: _currentPage = 0,
    onChangePage,
    onUploadData,
    onExportData,
    disabledExport,
    disabledUpload,
    exportType = 'public',
    useColumnFilter,
    onAddRow,
    onDeleteRows,
    ...rest
  } = props

  const $ref = useRef<AgGridReact>(null)
  const exportModal = useModal()
  const [totalCount, setTotalCount] = useState(_totalCount)
  const [currentPage, setCurrentPage] = useState(_currentPage)
  const useFilterModel = useRef(true)
  const filterModelRef = useRef<Record<string, unknown> | null>(null)

  const totalPages = useMemo(() => Math.ceil(totalCount / countPerPage), [totalCount, countPerPage])

  const { rangeOnPage, isAvailablePrevPage, isAvailableNextPage } = useMemo(() => {
    const startIdx = (currentPage - 1) * countPerPage + countPerPage + 1
    const endIdx = min([(currentPage + 1) * countPerPage, totalCount])

    return {
      rangeOnPage:
        countPerPage && totalPages ? `${Utils.getCommaNum(startIdx)} ~ ${Utils.getCommaNum(endIdx)} of ${Utils.getCommaNum(totalCount)}` : '',
      isAvailablePrevPage: currentPage < 1,
      isAvailableNextPage: currentPage >= totalPages - 1,
    }
  }, [currentPage, totalCount, countPerPage])

  const columns = useMemo(() => {
    const copied = cloneDeep(columnDefs) || []

    if (useColumnNo) {
      copied.unshift({
        headerName: 'No',
        cellRenderer: (params: ICellRendererParams) => currentPage * countPerPage + (params.node?.rowIndex ?? 0) + 1,
        width: 80,
      })
    }

    if (rowSelectable) {
      copied.unshift({
        pinned: true,
        width: 40,
        checkboxSelection: true,
        headerCheckboxSelection: rowSelection === 'multiple',
        showDisabledCheckboxes: true,
      })
    }

    return copied.map((col) => {
      const cellClass: string[] = typeof col.cellClass === 'string' ? [col.cellClass] : Array.isArray(col.cellClass) ? col.cellClass : []

      // 모든 컬럼 필터 제공
      if (useColumnFilter) {
        col.filter = 'agSetColumnFilter'
      }

      if (col.editable) {
        col.suppressKeyboardEvent = (params) => {
          const key = params.event.key
          return key === 'Backspace' || key === 'Delete'
        }

        if (typeof col.headerName === 'string') {
          col.headerName = `✎ ${col.headerName}`
          col.headerClass = TABLE_HEADER_EDITABLE
        }
      }

      col.cellClass = cellClass.join(' ')
      return col
    })
  }, [columnDefs, rowSelection, useColumnFilter, rowSelectable])

  const onPagination = useCallback(
    (page: number) => {
      if (onChangePage) {
        useFilterModel.current = false
        onChangePage(page)
        return
      }
      setCurrentPage(page)
      $ref.current?.api.paginationGoToPage(page)
    },
    [onChangePage],
  )
  const onFilterChanged = useCallback(
    (e: FilterChangedEvent<T>) => {
      if (onChangePage) {
        // Pagination으로 인한 필터 초기화가 아닌 경우 저장
        if (useFilterModel.current) {
          filterModelRef.current = e.api.getFilterModel()
        }
        return
      }
      setTotalCount(e.api.getDisplayedRowCount())
    },
    [_currentPage, currentPage, onChangePage],
  )

  const onFilterTextBoxChanged = useCallback((v: string) => {
    $ref.current?.api?.setQuickFilter(v)
  }, [])

  const overlayNoRow = useMemo(() => {
    if (isLoading) return '<span class="ag-overlay-loading-center">Loading...</span>'
    return `<div style="color:#7e7e7e;z-index:0;"><div style="margin-top:12px;font-size:14px;line-height:20px;font-weight:600;">조회결과 없음</div><div style="margin-top:2px;font-size:12px;line-height:18px;color:#b3b3b3;">필터를 설정해서 조회해 주세요.</div></div>`
  }, [isLoading])

  const onGetRowId = useMemo(() => {
    if (rowSelectable) {
      return (params: GetRowIdParams<T>) => {
        if (idKey !== 'id') return params.data?.[idKey as keyof T]?.toString() || ''
        return (params.data as unknown as { id: number | string })?.id?.toString() || ''
      }
    }
  }, [idKey, rowSelectable])

  const handleDeleteRow = useCallback(() => {
    const $api = $ref.current?.api
    if (!$api) return

    const selected = $api.getSelectedRows()
    if (!selected.length) return MToast.error('선택된 Row가 없습니다')

    MConfirm.show(`선택된 ${selected.length}개 Row를 정말 삭제하시겠어요?`, {
      onConfirm: () => onDeleteRows?.(selected),
    })
  }, [idKey, onDeleteRows])

  const handleCellValueChanged = useCallback((params: CellValueChangedEvent) => {
    if (['edit', 'paste'].includes(params.source || '') && params.oldValue !== params.newValue) {
      params.node.setSelected(true)

      // 한글인 경우 엔터로 바로 stop editing 처리가 안되는 ag-grid 버그가 있어서 직접 처리.
      if (typeof params.rowIndex === 'number') {
        params.api.stopEditing()
        params.api.setFocusedCell(params.rowIndex, params.column)
      }
    }
  }, [])

  useEffect(() => {
    if ($ref.current && customRef) (customRef as MutableRefObject<AgGridReact>).current = $ref.current
  }, [])

  useEffect(() => {
    setTotalCount(_totalCount)
  }, [_totalCount])

  useEffect(() => {
    if (filterModelRef.current) {
      $ref.current?.api.setFilterModel(filterModelRef.current)
      setTimeout(() => {
        useFilterModel.current = true
      }, 0)
    }
  }, [rowData])

  useEffect(() => {
    setCurrentPage(_currentPage)
  }, [_currentPage])

  useEffect(() => {
    if (isLoading) {
      $ref.current?.api?.showLoadingOverlay()
    } else {
      $ref.current?.api?.hideOverlay()
    }
  }, [isLoading])

  return (
    <VStack full>
      <AgGridReactWrap className={cx('ag-theme-balham', className)}>
        <AgGridReact<T>
          ref={$ref}
          {...agGridOptions}
          rowSelection={rowSelection}
          gridOptions={{
            ...defaultGridOptions,
            ...gridOptions,
            getRowId: onGetRowId,
          }}
          overlayNoRowsTemplate={overlayNoRow}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columns}
          pagination={pagination}
          paginationPageSize={countPerPage}
          onCellValueChanged={handleCellValueChanged}
          onFilterChanged={onFilterChanged}
          {...rest}
        />
      </AgGridReactWrap>

      <GridToolBarWrap gap={8} p="8px 12px" align="center">
        <GridToolBarSearch placeholder="Search..." onChange={onFilterTextBoxChanged} />
        {onAddRow && <Button fit kind="gray" size="sm" content="추가" onClick={onAddRow} />}
        {onDeleteRows && <Button fit kind="gray" size="sm" content="삭제" onClick={handleDeleteRow} />}
        {onUploadData && (
          <FileInput
            accept=".xlsx, .xls, .csv"
            trigger={<Button fit kind="secondary" disabled={disabledUpload} size="sm" content="업로드" />}
            onChange={onUploadData}
          />
        )}
        {onExportData && (
          <Button
            fit
            kind="secondary"
            size="sm"
            content="다운로드"
            disabled={disabledExport}
            onClick={exportType === 'private' ? exportModal.onOpenModal : onExportData}
          />
        )}
        {pagination && (
          <GridToolPagination gap={32} $loading={isLoading} align="center" justify="flex-end">
            <span>{rangeOnPage}</span>
            <HStack gap={4} align="center">
              <RemixIcon name="ri-skip-left-line" disabled={isAvailablePrevPage} onClick={() => onPagination(0)} />
              <RemixIcon name="ri-arrow-left-s-line" disabled={isAvailablePrevPage} onClick={() => onPagination(currentPage - 1)} />
              <span>
                Page {currentPage + 1} of {totalPages || totalPages + 1}
              </span>
              <RemixIcon name="ri-arrow-right-s-line" disabled={isAvailableNextPage} onClick={() => onPagination(currentPage + 1)} />
              <RemixIcon name="ri-skip-right-line" disabled={isAvailableNextPage} onClick={() => onPagination(totalPages - 1)} />
            </HStack>
          </GridToolPagination>
        )}
      </GridToolBarWrap>
      {exportModal.open && <GridExportModal onClose={exportModal.onCloseModal} onExportData={onExportData} />}
    </VStack>
  )
}

const GridTable = forwardRef(GridTableInner) as <T>(
  props: GridTableProps<T> & { ref?: ForwardedRef<AgGridReact> },
) => ReturnType<typeof GridTableInner>

export default GridTable
