import React from 'react'
import { GetDeliveryAreasReq } from '@/services/deliveryAreas/types'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import FilterList from '@/components/content/FilterList'
import Input from '@/components/content/Input'
import SelectBoxBool from '@/components/content/SelectBoxBool'
import SelectBoxCity from '@/pages/master/deliveryAreas/components/SelectBoxCity'

export const INIT_FORM: GetDeliveryAreasReq = {
  zipcode: '',
  city: undefined,
  remoteArea: null,
}

interface Props {
  isLoading: boolean
  onSave: () => void
  onSubmit: SubmitHandler<GetDeliveryAreasReq>
}

function DeliveryAreasForm({ isLoading, onSave, onSubmit }: Props) {
  const { setValue, watch, reset, handleSubmit } = useFormContext<GetDeliveryAreasReq>()
  const values = watch()

  return (
    <FilterList
      isLoading={isLoading}
      list={[
        [
          {
            label: '우편번호',
            value: <Input value={values.zipcode} onChange={(v) => setValue('zipcode', v)} />,
            width: 300,
          },
          {
            label: '시구분',
            width: 300,
            required: true,
            value: <SelectBoxCity value={values.city} onChange={(v) => setValue('city', v)} />,
          },
          {
            label: '구구분',
            width: 300,
            value: <Input value={values.district} onChange={(v) => setValue('district', v)} />,
          },
        ],
        [
          {
            label: '읍면동',
            width: 300,
            value: <Input value={values.town} onChange={(v) => setValue('town', v)} />,
          },
          {
            label: '배송리드타임',
            value: <Input type="number" block value={values.deliveryLeadDay} onChange={(v) => setValue('deliveryLeadDay', v)} />,
            width: 300,
          },
          {
            label: '도서산간여부',
            value: <SelectBoxBool block value={values.remoteArea} onChange={(v) => setValue('remoteArea', v)} />,
            width: 300,
          },
        ],
      ]}
      onReset={() => reset(INIT_FORM)}
      onSearch={handleSubmit(onSubmit)}
      onSave={onSave}
    />
  )
}

export default DeliveryAreasForm
