import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isNil, omitBy } from 'lodash-es'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import MLoader from '@/utils/MLoader'
import { API_URL, DEV_API_URL } from '@/utils/consts'
import { ApiStorage } from '@/utils/storage/ApiStorage'
import { AuthUtils } from '@/utils/AuthUtils'
import { Utils } from '@/utils/Utils'

type MomsApiRequestConfig = AxiosRequestConfig & { noAuth?: boolean }

export const http = axios.create({
  baseURL: Utils.isProd() ? API_URL : DEV_API_URL[ApiStorage.apiType.get()] || process.env.REACT_APP_API_URL,
  timeout: 100000,
})

http.interceptors.request.use(
  (config) => {
    if (config.method !== 'get' && !['/api/auth/menu', '/api/auth/check', '/api/grid/search'].includes(config.url || '')) {
      MLoader.show()
    }

    return config
  },
  function (error) {
    MLoader.hide()
    return Promise.reject(error)
  },
)

http.interceptors.response.use(
  (config) => {
    MLoader.hide()
    return config
  },
  function (error) {
    if (error?.response?.data?.meta?.errorCode === 'ERROR_UNAUTHORIZED') {
      alert('토큰이 유효하지 않습니다.')
      AuthUtils.logout()
    }

    MLoader.hide()
    return Promise.reject(error)
  },
)

const getOptions = (config?: MomsApiRequestConfig) => {
  const { headers, noAuth, ...rest } = config || {}
  const authToken = AuthStorage.auth.get()

  return {
    ...rest,
    headers: omitBy(
      {
        'Content-Type': 'application/json',
        ...(noAuth ? undefined : { Authorization: `token ${authToken}` }),
        ...headers,
      },
      isNil,
    ),
  } as AxiosRequestConfig
}

export function get<T, R = UnknownMap>(url: string, queryParams?: R, config?: Omit<MomsApiRequestConfig, 'params'>): Promise<T> {
  return new Promise((resolve, reject) => {
    http
      .get<T>(`${url}${Utils.qsStringify<R>(queryParams)}`, getOptions(config))
      .then((result) => {
        if (result.data) {
          resolve(result.data)
        } else {
          reject({ error: { message: 'empty' } })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function post<T, R = UnknownMap>(url: string, body: R, config?: MomsApiRequestConfig): Promise<T> {
  return new Promise((resolve, reject) => {
    http
      .post<T>(url, body, getOptions(config))
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function put<T, R = UnknownMap>(url: string, body: R, config?: MomsApiRequestConfig): Promise<T> {
  return new Promise((resolve, reject) => {
    http
      .put<T>(url, body, getOptions(config))
      .then((result) => {
        if (result.data) {
          resolve(result.data)
        } else {
          reject({ error: { message: 'empty' } })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function patch<T, R = UnknownMap>(url: string, body: R, config?: MomsApiRequestConfig): Promise<T> {
  return new Promise((resolve, reject) => {
    http
      .patch<T>(url, body, getOptions(config))
      .then((result) => {
        if (result.data) {
          resolve(result.data)
        } else {
          reject({ error: { message: 'empty' } })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function deleteR<T>(url: string, id?: number | string | null, queryParams?: UnknownMap, config?: MomsApiRequestConfig): Promise<T> {
  return new Promise((resolve, reject) => {
    const deleteUrl = !isNil(id) ? `${url}/${id}` : url

    http
      .delete<T>(deleteUrl, getOptions({ params: queryParams || {}, ...config }))
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function getExcel(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
  if (config && config.method === 'post') {
    return new Promise((resolve, reject) => {
      http
        .post(url, config.data, getOptions({ ...config, responseType: 'blob' }))
        .then((result) => {
          if (result.data) {
            resolve(result)
          } else {
            reject({ error: { message: 'empty' } })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return new Promise((resolve, reject) => {
    http
      .get(url, getOptions({ ...config, responseType: 'blob' }))
      .then((result) => {
        if (result.data) {
          resolve(result)
        } else {
          reject({ error: { message: 'empty' } })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default { get, post, put, patch, delete: deleteR }
