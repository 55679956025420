import Input from '@/components/content/Input'

export const dateDefaultOptions = {
  locale: 'ko',
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
  dateFormat: 'yyyy-MM-dd',
  customInput: <Input readOnly />,
} as const
